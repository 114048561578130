import { fadeOut, fadeIn, containEvent, pjaxFetch, signalSuccess } from './utilities'
import { activate } from './activate';
import { log } from './logging';

class Refresher {
  constructor(el) {
    this.container = el;
    this.url = el.dataset.url || el.dataset.refreshing;
    this.method = el.dataset.method;
    this.data = el.dataset.params;
    this.quiet = el.dataset.quiet;
    this.wrapperNodeName = el.parentNode ? el.parentNode.nodeName : "DIV";
    this.container.addEventListener("refresh", this.refresh.bind(this));
    if (el.dataset.ttl) {
      this.scheduleRefresh(el.dataset.ttl);
    }
  }

  refresh(e) {
    containEvent(e);
    if (this.url) {
      this.container.classList.add('working');
      pjaxFetch(this.url).then(this.replace.bind(this));
    }
  }

  scheduleRefresh(ttl) {
    if (this.timer) window.clearTimeout(this.timer);
    this.timer = window.setTimeout(this.refresh.bind(this), ttl);
  }

  replace(response) {
    const wrapper = document.createElement(this.wrapperNodeName);
    wrapper.innerHTML = response;
    const new_container = wrapper.firstElementChild;
    const new_container_display = new_container.style.display
    new_container.style.display = 'none';
    this.container.parentElement.insertBefore(new_container, this.container);
    if (this.quiet) {
      new_container.style.display = this.container.style.display;
      this.container.remove();
      this.container = new_container;
    } else {
      fadeOut(this.container, () => {
        this.container.dispatchEvent(new CustomEvent('refreshed', {
          detail: {
            el: new_container,
          }
        }));
        this.container.remove();
        new_container.style.display = new_container_display;
        fadeIn(new_container);
        signalSuccess(new_container);
      });
    }
    // pass the new nodes to all our activator functions
    // this is likely to reinitialise the refresher, so we should not try to adopt the new content here.
    activate(new_container);
  }
}

function refresher(el) {
  return new Refresher(el);
}

function reloader(el) {
  if (el.dataset.reloading) {
    if (el.dataset.reloading === 'now') {
      reload()
    } else {
      window.setTimeout(reload, parseInt(el.dataset.reloading, 10));
    }
  }
}

function reload() {
  window.location.reload();
}

function refresh(el) {
  el.dispatchEvent(new Event('refresh'));
}


export {
  refresher,
  reloader,
  refresh,
}; 