let mask;

function zoomOnClick(el) {
  const url = el.dataset.zoomed;
  if (url) {
    if (el.nodeName === 'IMG') {
      return zoomImage(url);
    }
    if (el.nodeName === 'VIDEO') {
      return zoomVideo(url);
    }
  }
  zoom(el);
}

function zoom(el) {
  const preview_el = el.cloneNode(true);
  showInMediaOverlay(preview_el);
}

function zoomImage(url) {
  const img = new Image();
  img.onload = () =>  {
    mask.classList.remove("loading");
  };
  showInMediaOverlay(img);      // must precede load() or we may not hit the onLoad
  img.src = url;
}

function zoomVideo(url) {
  const el = document.createElement('video');
  const src = document.createElement('source');
  showInMediaOverlay(el);
  el.appendChild(src)
  src.onLoad = () =>  mask.classList.remove("loading");
  src.src = url;
}

function hideMediaOverlay() {
  if (mask && mask.classList.contains('up')) {
    mask.classList.remove('up');
  }
}

function showInMediaOverlay(el) {
  if (!mask) {
    mask = document.getElementById('zoom');
    mask.addEventListener('click', hideMediaOverlay, false);
  }
  mask.querySelectorAll('img, video').forEach((el) => el.remove());
  mask.appendChild(el);
  mask.classList.add("loading");
  mask.classList.add('up');
}


export {
  zoom,
  zoomImage,
  zoomOnClick,
  zoomVideo,
}