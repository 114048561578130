let logging = true;

function startLogging() {
  logging = true;
}

function stopLogging() {
  logging = false;
}

function log() {
  if (logging && console && console.log) console.log(...arguments);
}

function warn() {
  if (logging && console && console.warn) console.warn(...arguments);
}

function error() {
  if (logging && console && console.error) console.error(...arguments);
}

export {
  startLogging,
  stopLogging,
  log,
  warn,
  error,
}; 