import waitForElementTransition from 'wait-for-element-transition';
import { log } from './logging';

let csrf_token;

function debounce(func, wait = 200) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}


function getOffset(el) {
  let mybcr = el.getBoundingClientRect();
  let parentNode = el.parentNode
  if (parentNode) {
    let pnbcr = parentNode.getBoundingClientRect();
    return {
      top: mybcr.top - pnbcr.top,
      right: mybcr.right - pnbcr.right,
      bottom: mybcr.bottom - pnbcr.bottom,
      left: mybcr.left - pnbcr.left,
    }
  }
  return mybcr;
}

function zeroPad(n, width) {
  width ||= 2;
  n = n + '';
  while (n.length < width) {
    n = `0${n}`
  }
  return n;
}


function disable(el) {
  el.classList.add('disabled');
  el.querySelectorAll('input, select, textarea').forEach((input) => {
    input.disabled = true;
  });
}


function enable(el) {
  el.classList.remove('disabled');
  el.querySelectorAll('input, select, textarea').forEach((input) => {
    input.disabled = false;
  });
}


function replaceWith(el, new_el) {
  log("🌼 replacing", el, 'with', new_el);
  fadeOut(el, () => {
    el.parentNode.insertBefore(new_el, el.nextSibling);
    fadeIn(new_el);
  })
}

function signalSuccess(el) {
  el.onAnimationEnd = () => {
    el.style.animation = null;
  }
  resetAnimation(el, 'affirmation 0.4s');
}

function signalError(el) {
  el.onAnimationEnd = () => {
    el.style.animation = null;
  }
  resetAnimation(el, 'contradiction 1s');
}

function fadeIn(el, callback) {
  el.onanimationend = () => {
    if (callback) callback();
    el.style.animation = null;
  };
  resetAnimation(el, 'fadein 0.4s');
}


function fadeOut(el, callback) {
  el.onanimationend = () => {
    if (callback) callback(el);
    el.style.animation = null;
    if (el.parentNode) {
      el.parentNode.removeChild(el);
    }
  };
  resetAnimation(el, 'fadeout 0.4s');
}


function slideDown(el, callback) {
  const h = el.dataset.former_height || el.clientHeight;
  el.style.maxHeight = '0px';
  el.classList.add('slide-in-out');
  waitForElementTransition(el).then(() => {
    el.classList.remove('slide-in-out');
    el.style.maxHeight = null;
    delete el.dataset.height;
    el.style.overflow = "visible";
    if (callback) callback();
  });
  window.requestAnimationFrame(() => {
    el.style.maxHeight = `${h}px`;
  });
}

function resetAnimation(el, animation = null) {
  el.style.animation = 'none';
  el.offsetHeight; /* triggers reflow */
  el.style.animation = animation; 
}

function slideUp(el, callback) {
  // set max height to current height
  el.classList.remove('slide-in-out');
  el.style.maxHeight = null;
  el.style.maxHeight = el.dataset.former_height = el.clientHeight;
  // add transition to max-height
  el.classList.add('slide-in-out');
  el.style.overflow = "hidden";
  // define callback for end of transition
  waitForElementTransition(el).then(() => {
    log("end slideUp transition");
    el.classList.remove('slide-in-out');
    if (callback) callback();
  });
  // set max height to zero
  window.requestAnimationFrame(() => {
    log("begin slideUp transition");
    el.style.maxHeight = '0px';
  });
}

function toggleSlide(el, classname) {
  if (el.classList.contains(classname)) {
    slideUp(el, () => {
      el.classList.remove(classname);
    });
  } else {
    el.classList.add(classname);
    slideDown(el);
  }
}

function toggleClass(el, classname) {
  if (el.classList.contains(classname)) {
    el.classList.remove(classname);
  } else {
    el.classList.add(classname);
  }
}


function hasFixedPosition(node) {
  while (node && node.nodeName.toLowerCase() !== 'body') {
    if (window.getComputedStyle(node).getPropertyValue('position').toLowerCase() === 'fixed') {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}


function containEvent(e) {
  if (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
  }
}


function addAuthHeader(opts) {
  if (!csrf_token) {
    const meta = document.querySelector('meta[name="csrf-token"]')
    if (meta) csrf_token = meta.content;
  }
  const options = opts || {};
  options.headers ||= {};
  options.headers["X-CSRF-Token"] = csrf_token;
  options.headers["X-PJAX"] = true;
  return options;
}

function authorizeXhr(xhr) {
  if (!csrf_token) {
    const meta = document.querySelector('meta[name="csrf-token"]')
    if (meta) csrf_token = meta.content;
  }
  xhr.setRequestHeader("X-CSRF-Token", csrf_token)
  xhr.setRequestHeader("X-PJAX", true)
}

async function pjaxFetch(url, opts={}) {
  const options = addAuthHeader(opts);
  const response = await fetch(url, options);
  if (response.ok) return response.text();
  else return Promise.reject({
    status: response.status,
    statusText: response.statusText,
  });
}

async function jsonFetch(url, opts={}) {
  const options = addAuthHeader(opts);
  options.headers["Content-Type"] = "application/json";
  const response = await fetch(url, options);
  if (response.ok) return response.json();
  else return Promise.reject({
    status: response.status,
    statusText: response.statusText,
  });
}


export {
  debounce,
  getOffset,
  zeroPad,
  hasFixedPosition,
  replaceWith,
  fadeOut,
  fadeIn,
  resetAnimation,
  slideDown,
  slideUp,
  toggleSlide,
  toggleClass,
  disable,
  enable,
  containEvent,
  pjaxFetch,
  jsonFetch,
  authorizeXhr,
  signalSuccess,
  signalError,
};