import { DirectUpload } from "@rails/activestorage"
import { fadeOut, containEvent } from "../droom/utilities"
import { zoomImage } from "../droom/zoomer"

class ImagePicker {
  constructor(el, opts={}) {
    this._container = el;
    this._preview = el.querySelector('.preview');
    this._field = el.querySelector('input[type="file"]');
    this._url = this._field.dataset.directUploadUrl;
    this._field.addEventListener("change", this.pickFiles.bind(this));
    this._thumbnails = el.querySelector('.thumbnails');
    this._multi = opts.multi && this._thumbnails;

    if (this._multi) {
      this._thumbnails.querySelectorAll('.image').forEach((img) => this.observeThumbnail(img));
      this._preview_template = el.querySelector('span.image.template');
      this.showDefaultPreview();
      this._container.addEventListener("mouseleave", this.showDefaultPreview.bind(this));
    }

    this._container.addEventListener("click", this.interceptDeleteClick.bind(this));
    this._preview.addEventListener('dragenter', this.dragEnter.bind(this), false)
    this._preview.addEventListener('dragleave', this.dragLeave.bind(this), false)
    this._preview.addEventListener('dragover', this.dragOver.bind(this), false)
    this._preview.addEventListener('drop', this.dropFiles.bind(this), false)
    this._preview.addEventListener('click', this.zoomPreview.bind(this), false)
  }

  receiveFiles(files) {
    const skipped = [];
    Array.from(files).forEach((file) => {
      if (fileIsImageOrPdf(file)) {
        let holder;
        if (this._multi) {
          holder = this.addThumbnail();
          this.observeThumbnail(holder);
        } else {
          holder = this._preview;
        }
        this.capturePreview(holder, file);
        this.uploadFile(holder, file);
      } else {
        skipped.push(file);
      }
    });
    if (skipped.length) {
      const filenames = skipped.map((f) => f.name).join(',')
      alert(`Sorry, we can only take image files here. These files were skipped: ${filenames}.`)
    }
    this._field.value = "";
  }


  capturePreview(holder, file) {
    if (fileIsPdf(file)) {
      const url = "/images/processing_pdf.png";
      holder.style.backgroundImage = `url("${url}")`;
      holder.classList.add('unprocessed')
      if (this._multi) this.showPreview(null, holder);

    } else if (fileIsImage(file)) {
      const picker = this;
      const img = new Image;
      img.onload = () => {
        const data_url = lowResPreview(img, 128);
        holder.style.backgroundImage = `url("${data_url}")`;
        holder.classList.add('previewing');
        if (picker._multi) picker.showPreview(null, holder);
        URL.revokeObjectURL(img.src);
      }
      img.src = URL.createObjectURL(file);
    }
  }

  // TODO: prevent form submission while uploading
  //
  uploadFile(holder, file) {
    const upload = new DirectUpload(file, this._url, new ImageUpload(file, holder));
    upload.create((error, blob) => this.fileUploaded(error, blob, holder));
  }

  fileUploaded(error, blob, holder) {
    if (error) {
      console.error("UPLOAD FAIL", error);
    } else {
      const file_id = blob.signed_id;

      // TODO: delete existing hidden field
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute("type", "hidden");
      hiddenField.setAttribute("value", file_id);
      hiddenField.name = this._field.name;
      holder.appendChild(hiddenField);

      // new file will displace old: explicit detach might create a race condition
      const detach_field = holder.querySelector('input[data-role="detacher"]');
      if (detach_field) detach_field.value = 'false';

      const img = new Image;
      img.onload = () => {
        holder.style.backgroundImage = `url("${preview_url}")`;
        holder.dataset.original = `/control/blobs/original/${blob.id}/${blob.filename}`
        holder.dataset.zoomed = `/control/blobs/zoomed/${blob.id}/${blob.filename}`
        if (this._multi) this.showPreview(null, holder);
      }
      const preview_url = `/control/blobs/preview/${blob.id}/${blob.filename}`
      img.src = preview_url;
    }
  }

  // Input event handlers

  interceptDeleteClick(e) {
    const target = e.target;
    if (target && target.tagName === 'A' && target.classList.contains('detach')) {
      containEvent(e);
      if (this._multi) {
        this.removeThumbnail(target.parentNode);
      } else {
        this._preview.style.backgroundImage = "";
        this._preview.classList.remove('previewing');
        delete this._preview.dataset.original;
        delete this._preview.dataset.zoomed;
        const attach_field = this._preview.querySelector('input[data-role="attacher"]');
        if (attach_field) attach_field.remove();
        const detach_field = this._preview.querySelector('input[data-role="detacher"]');
        if (detach_field) detach_field.value = 'true';
      }
    }
  }

  pickFiles(e) {
    this.receiveFiles(this._field.files);
  }

  dropFiles(e) {
    unbubble(e);
    if (this.checkPayload(e)) {
      this.showNormal();
      this.receiveFiles(e.dataTransfer.files);
    } else {
      this.showUnwilling();
      setTimeout(this.showNormal.bind(this), 500);
    }
  }

  dragEnter(e) {
    unbubble(e);
    this.checkPayload(e);
    this._preview.classList.add('dragover');
  }

  dragLeave(e) {
    this.showNormal();
    unbubble(e);
  }

  dragOver(e) {
    unbubble(e);
    this.checkPayload(e);
  }

  checkPayload(e) {
    const itemList = e.dataTransfer.items;
    if (itemList.length > 0) {
      const image_files = [];
      for (var i = 0; i < itemList.length; i++) {
        if (fileIsImageOrPdf(itemList[i])) {
          image_files.push(itemList[i]);
        }
      }
      if (itemList.length == image_files.length) {
        this.showWilling();
        return true;
      } 
      this.showUnwilling();
      return false;
    }
    this.showNormal();
    return false;
  }

  showWilling() {
    this._preview.classList.remove('undroppable');
    this._preview.classList.add('droppable');
  }

  showUnwilling() {
    this._preview.classList.add('undroppable');
    this._preview.classList.remove('droppable');
  }

  showNormal() {
    this._preview.classList.remove('undroppable');
    this._preview.classList.remove('droppable');
  }

  zoomPreview(e) {
    const target = e.target;
    if (target && !(target.tagName == 'A' && target.classList.contains('detach'))) {
      const zoomed_url = this._preview.dataset.zoomed;
      if (zoomed_url) {
        if (e) e.preventDefault();
        zoomImage(zoomed_url);
      }
    }
  }

  // Multiple image picker has thumbnails and hover-to-preview
  //
  addThumbnail() {
    const holder = this._preview_template.cloneNode(true);
    holder.classList.remove('template');
    this._thumbnails.insertBefore(holder, this._thumbnails.querySelector('span.adder'));
    return holder;
  }

  removeThumbnail(el) {
    const hidden_field = el.querySelector('input[type="hidden"]');
    if (hidden_field) hidden_field.remove()
    fadeOut(el);
  }

  observeThumbnail(img) {
    img.addEventListener("mouseenter", this.showPreview.bind(this));
  }

  showDefaultPreview() {
    const first_thumbnail = this._thumbnails.querySelector('.image')
    if (first_thumbnail) {
      this.showPreview(null, first_thumbnail);
    }
  }

  showPreview(e, el) {
    const previewed_el = el || e.currentTarget;
    const bg = previewed_el.style.backgroundImage;
    if (bg) {
      this._preview.style.backgroundImage = previewed_el.style.backgroundImage;
      this._preview.classList.add('previewing');
      this._preview.dataset.original = previewed_el.dataset.original;
      this._preview.dataset.zoomed = previewed_el.dataset.zoomed;
      if (previewed_el.classList.contains('unprocessed')) {
        this._preview.classList.add('unprocessed');
      } else {
        this._preview.classList.remove('unprocessed');
      }
    } else {
      this._preview.classList.remove('previewing');
    }
  }


}


// ImageUpload is a delegate for the rails DirectUpload helper
// and must respond to directUploadWillStoreFileWithXHR

class ImageUpload {
  constructor(file, el) {
    this._el = el;
    this._bar = document.createElement('span');
    this._bar.classList.add('bar');
    this._el.appendChild(this._bar);
    this.directUploadWillStoreFileWithXHR = this.initUpload.bind(this);
  }

  initUpload(request) {
    request.upload.addEventListener("progress", this.progress.bind(this));
  }

  progress(e) {
    if (e.loaded && e.total) {
      const perc = 100 * e.loaded / e.total;
      this._bar.style.width = `${perc}%`;
      if (e.loaded == e.total) {
        this._el.classList.remove('picked');
        this._el.classList.add('present');
        this._el.classList.add('new');
        this._bar.style.opacity = 0
      }
    }
  }
}

function lowResPreview(img, size=48) {
  let h, w, x, y;
  if (img.height > img.width) {
    w = size;
    h = w * (img.height / img.width);
    x = 0;
    y = (size - h) / 2;
  } else {
    h = size;
    w = h * (img.width / img.height);
    x = (size - w) / 2;
    y = 0;
  }
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, x, y, w, h);
  return canvas.toDataURL('image/png');
}

function unbubble(e) {
  e.preventDefault();
  e.stopPropagation();
}

function fileIsImage(file) {
  return file && (!file.type || /^image/.test(file.type));
}

function fileIsPdf(file) {
  return file && (!file.type || /pdf/.test(file.type));
}

function fileIsImageOrPdf(file) {
  return fileIsImage(file) || fileIsPdf(file)
}

function imagePicker(el) {
  return new ImagePicker(el, {multi: true});
}

function singleImagePicker(el) {
  return new ImagePicker(el, {multi: false});
}

export {
  imagePicker,
  singleImagePicker
};