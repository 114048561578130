/*
  `activations` is a global set of decoration functions, always in the form `(el) => { }`.
  Whenever new content is added to the page (including on page load) it is passed to all of those decorators.
*/


import { log, warn } from './logging';

window.activations ||= new Map();

function activate(el) {
  log('🏁 activate', el, window.activations);
  window.activations.forEach((fn, label) => {
    fn.call(null, el);
  });
}

// The most common pattern here is to find all child elements of a type,
// within the newly provided content, and do fn to each one.
//
function addSelectorActivation(label, selector, fn) {
  addActivation(label, (outer_el) => {
    outer_el.querySelectorAll(selector).forEach((inner_el) => {
      fn.call(null, inner_el);
    });
    if (outer_el.nodeType !== Node.DOCUMENT_NODE && outer_el.matches(selector)) {
      fn.call(null, outer_el);
    }
  });
}

// or sometimes we want to add a listener to the element and do the something later.
// (many of the selector activations also follow this pattern, but do the event-handling themselves)
//
function addListenerActivation(label, selector, event_name, fn) {
  addSelectorActivation(label, selector, (el) => {
    el.addEventListener(event_name, (e) => {
      if (e.target === el) {
        e.preventDefault();
        fn.call(null, el);
      }
    });
  })
}

function addActivation(label, fn) {
  if (window.activations.has(label)) {
    warn(`⚠️ replacing activator ${label} with`, fn);
    // debugger;
  }
  window.activations.set(label, fn);
}

function removeActivation(label) {
  window.activations.delete(label);
}


export {
  activate,
  addActivation,
  addListenerActivation,
  addSelectorActivation,
  removeActivation,
}
