import { pjaxFetch, fadeOut, slideDown, slideUp, toggleSlide, toggleClass, containEvent, enable, disable } from './utilities';
import { activate } from './activate';
import { refresh } from './refreshers';
import { log } from './logging';
import { signalSuccess, replaceWith } from './utilities';

// temporary overreach...
import { singleImagePicker } from '../lmdr/image_picker';


async function revealer(el) {
  const revelation = (e) => {
    let upness;
    const shows = document.querySelectorAll(el.dataset.reveals);
    const hides = document.querySelectorAll(el.dataset.conceals);
    if (el.nodeName === 'INPUT') {
      if (el.type === 'checkbox' || el.type === 'radio') {
        upness = el.checked;
      } else {
        upness = el.value.length > 0;
      }
      if (upness) {
        shows.forEach((target_el) => {
          target_el.classList.add('expanded');
          slideDown(target_el);
          target_el.querySelectorAll('input, textarea').forEach((input_el) => {
            input_el.removeAttribute('disabled');
          });
        });
        hides.forEach((target_el) => {
          target_el.classList.remove('expanded');
          slideUp(target_el);
          target_el.querySelectorAll('input, textarea').forEach((input_el) => {
            input_el.disabled = true;
          });
        });
      } else {
        shows.forEach((target_el) => {
          target_el.querySelectorAll('input, textarea').forEach((input_el) => {
            input_el.disabled = true;
          });
          slideUp(target_el, () => {
            target_el.classList.remove('expanded');
          });
        });
        hides.forEach((target_el) => {
          target_el.classList.add('expanded');
          target_el.querySelectorAll('input, textarea').forEach((input_el) => {
            input_el.removeAttribute('disabled');
          });
          slideDown(target_el);
        });
      }
    } else {
      shows.forEach((target_el) => toggleSlide(target_el, 'expanded'));
      hides.forEach((target_el) => toggleSlide(target_el, 'expanded'));
      toggleClass(el, 'expanded');
    }
  }

  // stateful revealers should be run on load and any change;
  // non-stateful are just toggles and should only be run on click.
  el.addEventListener("click", revelation);
  if (el.nodeName === 'INPUT') {
    el.addEventListener("input", revelation);
    revelation();
  }
}


async function subordinateField(el) {
  const selector = el.dataset.dependent;
  document.querySelectorAll(selector).forEach((checkbox) => {
    const checker = (e) => {
      if (checkbox.checked) {
        enable(el);
      } else {
        disable(el);
      }
    };
    checkbox.addEventListener('click', checker);
    checker();
  });
}

function autoForm(form) {
  const inputs = form.querySelectorAll('input')
  inputs.forEach((input) => {
    input.addEventListener('input', (e) => {
      const label = input.closest('label');
      if (label) label.classList.add('working');
      form.classList.add('working');
      form.submit();
    })
  })
}

function statusForm(form) {
  const stumbit = form.querySelector('input[type="submit"]');
  const inputs = form.querySelectorAll('input[type="checkbox"]');
  const initial_state = checkboxState(inputs);
  const fields = form.querySelectorAll('input[type="text"], textarea');
  const comment_state = inputState(fields);
  const setAvailability = (e) => {
    const present_state = checkboxState(inputs);
    const present_comment_state = inputState(fields); 
    if (present_state === initial_state && present_comment_state === comment_state) {
      stumbit.classList.add('unavailable')
    } else {
      stumbit.classList.remove('unavailable')
    }
  };

  // set submit button state and check/uncheck related inputs when form checkboxes change
  inputs.forEach((input) => {
    input.addEventListener('click', (e) => {
      if (input.checked && input.dataset.checks) {
        // other fields checked with this one
        form.querySelectorAll(input.dataset.checks).forEach((junior) => junior.checked = true)
      } else if (!input.checked && input.dataset.unchecks) {
        // other fields unchecked with this one
        form.querySelectorAll(input.dataset.unchecks).forEach((senior) => senior.checked = false)
      }
      if (input.checked && input.dataset.cancels) {
        // other fields unchecked by checking this one
        form.querySelectorAll(input.dataset.cancels).forEach((contra) => {
          contra.checked = false;
          contra.dispatchEvent(new Event('input', {bubbles: true}));
        });
      }
      setAvailability();
    });
  })

  // set submit button state when form text field changes
  // nb. disabled fields are ignored, so the revealer must dis/able them before we hit this function
  fields.forEach((field) => {
    field.addEventListener('input', (e) => {
      setAvailability();
    });
  });

  form.addEventListener('submit', (e) => {
    e.preventDefault();
    e.stopPropagation();
    form.classList.add('waiting');
    stumbit.classList.add('unavailable');
    stumbit.value ='saving';
    submitForm(form).then((response) => {
      if (form.dataset.affected) {
        document.querySelectorAll(form.dataset.affected).forEach((affected) => {
          affected.dispatchEvent(new Event('refresh', {bubbles: true}));
        });
      }
    })
  });
}

function submitForm(el) {
  const url = el.action;
  const methodField = el.querySelector('input[name="_method"]')
  let method = methodField ? methodField.value : el.method;
  if (method === 'put' || method === 'patch') {
    method = "PUT";
  }
  const body = new FormData(el);
  return fetch(url, { method, body });
}

function serializeFormAsJSON(el) {
  // return new FormData(el);
  return JSON.stringify(Object.fromEntries(new FormData(el)));
};

function submitFormLink(el) {
  const form = el.closest('form');
  if (form) {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (!el.dataset.confirm || window.confirm(el.dataset.confirm)) {
        el.classList.add('working');
        form.submit();
      }
    });
  }
}


// cheap and cheerful...
function checkboxState(inputs) {
  const state = []
  inputs.forEach((input) => state.push(`${input.name}=${input.checked}`));
  return state.join('|');
}

function inputState(inputs) {
  const state = []
  inputs.forEach((input) => {
    if (!input.disabled) {
      state.push(`${input.name}=${input.value}`);
    }
  });
  return state.join('|');
}


async function appendsFields(link) {
  let destination;
  if (link.dataset.affected) {
    destination = document.querySelector(link.dataset.affected);
  } else {
    destination = document.parentElement;
  }
  const max = destination.dataset.max;

  link.addEventListener('click', (e) => {
    e.preventDefault();
    link.classList.add('working');
    pjaxFetch(link.href)
      .then((text) => {
        link.classList.remove('working');
        const existing = destination.querySelectorAll('fieldset.repeating');
        const counters = Array.from(existing).map((el) => parseInt(el.dataset.counter || 0));
        const max_counter = Math.max(...counters);
        const next_counter = max_counter ? max_counter + 1 : existing.length;
        const addition = text.replace(/field_counter/g, next_counter);
        const wrapper = document.createElement('div');
        wrapper.innerHTML = addition;
        const new_el = wrapper.firstElementChild;
        activate(new_el);
        destination.insertBefore(new_el, link);
        slideDown(new_el);
        if (max && next_counter >= max) {
          link.style.display = 'none';
        }
      });
  });
}

async function removesFields(link) {
  link.addEventListener('click', (e) => {
    e.preventDefault();
    link.classList.add('working');
    const holder = link.closest(link.dataset.affected || link.dataset.holder || '.holder');
    const delete_field = holder.querySelector('input[data-role="destroy"]');
    if (delete_field) {
      delete_field.value = 1;
      holder.querySelectorAll('input, textarea').forEach((el) => {
        if (!el.dataset.requiredForDestruction) {
          el.disabled = true;
        }
      });
    }
    const remover = () => {
      if (delete_field) {
        holder.style.display = 'none';
      } else {
        holder.remove();
      }
    }
    slideUp(holder, remover);
  });
}


async function remoteLink(link) {
  link.addEventListener('click', async (e) => {
    const method = link.dataset.method || 'GET';
    link.classList.add('working');
    if (link.remote || link.dataset.remote || link.dataset.method) {
      containEvent(e);
    }

    const action = () => {
      pjaxFetch(link.href, { method: method })
        .then((response) => enactRemoteLink(link, response));
    }

    if (link.dataset.confirm) {
      if (confirm(link.dataset.confirm)) {
        action();
      }
    } else {
      action();
    }
  });
}

function enactRemoteLink(link, response) {
  // restore and flash clicked link
  link.classList.remove('working');

  // possible action on one or more other elements
  if (link.dataset.affected) {
    document.querySelectorAll(link.dataset.affected).forEach((el) => refresh(el));
  }
  
  if (link.dataset.removed) {
    document.querySelectorAll(link.dataset.removed).forEach((el) => fadeOut(el));
  }
  if (link.dataset.goto) {
    window.location.href = link.dataset.goto;
  } else if (response) {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = response;
    const new_el = wrapper.firstElementChild;

    // possible insertion / replacement of single node using returned html
    if (link.dataset.aftered) {
      const marker = document.querySelector(link.dataset.aftered);
      if (marker) {
        marker.parentElement.insertAfter(new_el, marker);
        activate(new_el);
      }
    }
    if (link.dataset.befored) {
      const marker = document.querySelector(link.dataset.befored);
      if (marker) {
        marker.parentElement.insertBefore(new_el, marker);
        activate(new_el);
      }
    }
    if (link.dataset.replaced) {
      const marker = document.querySelector(link.dataset.replaced);
      if (marker) {
        replaceWith(marker, new_el);
        activate(new_el);
        link = new_el;
      }
    }

    signalSuccess(link);

    if (link.dataset.redirect) {
      window.location.href = link.dataset.redirect;
    }
  }
}

function activePlaceholder(el) {
  log("🐷 activePlaceholder", el);
  el.addEventListener('click', (e) => {
    log("🐷 activePlaceholder click", el.placeholder);
    if (el.value === "") {
      el.value = el.placeholder;
    }
  });
}

export {
  revealer,
  appendsFields,
  removesFields,
  autoForm,
  statusForm,
  submitFormLink,
  subordinateField,
  remoteLink,
  enactRemoteLink,
  activePlaceholder,
};